import React, { useEffect, useRef, useState, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { loader } from 'helpers/generic';
import Tank from 'components/Tank';
import DeviationModal from './DeviationModal';
import { hasCapability } from 'helpers/user';
import CarrierSendLogsModal from './CarrierSendLogsModal';
import OcrPopup from './OcrPopup';

const Tr = styled.tr`
	position: relative;

	& > td {
		text-align: unset !important;
		padding: 20px 10px;

		&:first-of-type {
			padding-left: ${props => props.isDeviation ? 40 : 0}px;
		}
	}

	.probes_wrapper {
		#loader {
			height: 20px;
			margin-left: 5px;
		}
	}
`

const TableDetails = styled.table`
	width: 100%;

	td {
		font-size: 12px !important;

		padding-top: 5px;
		padding-bottom: 5px;
		border-bottom: 1px solid var(--bs-gray-300);

		&:nth-child(1) {
			width: 20px;
		}

		&:nth-child(2) {
			padding-left: 5px;
			font-weight: 600;
		}

		&:nth-child(3) {
			width: 100px;
			text-align: right;
		}
	}
`;

const IncidencesWrapper = styled.div`

`;


const TableIncidenceType = styled.table`
	td {
		font-size: 10px !important;

		&:nth-child(1) {
			width: 20px;
		}
	}
`;

const IncidenceQuantities = styled.table`
	width: 100%;

	td {
		font-size: 10px !important;

		&:nth-child(1) {
			width: 70px;
			text-align: left;
		}

		&:nth-child(2) {
			input {
				padding: 0 5px;
				font-size: 12px;
				text-align: right;
			}
		}
	}
`;

const ButtonClose = styled.button`
	background: transparent;
	border: 0;

	&:hover {
		i {
			background: var(--plenoil-blue) !important;
		}

		div {
			color: var(--plenoil-blue);
		}
	}

	i {
		width: 18px;
		height: 18px;
		background: var(--bs-dark);
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
	}

	div {
		font-size: 12px;
	}
`;

const ButtonCancel = styled.button`
	position: absolute;
	bottom: 50px;
	right: 10px;
	background: transparent;
	border: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: var(--bs-red);

	i {
		width: 18px;
		height: 18px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
	}
`;

const ButtonDelete = styled.button`
	position: absolute;
	bottom: 10px;
	right: 10px;
	background: transparent;
	border: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: var(--bs-red);

	i {
		width: 18px;
		height: 18px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
	}
`;

let axiosCancelToken = null;

export default function TankerTrucks(props) {
	const [data, setData] = useState({});

	const [forceReload, setForceReload] = useState(false);
	const [deviationModal, setDeviationModal] = useState(false);
	const [carrierSendLogsModal, setCarrierSendLogsModal] = useState(false);
	const [probeData, setProbeData] = useState(null);
	const [probeLoading, setProbeLoading] = useState(false);
	const [editingObservations, setEditingObservations] = useState(false);
    const [ocrDetails, _setOcrDetails] = useState(false);
	const ocrDetailsRef = useRef(null);
	const setOcrDetails = (data) => {
		_setOcrDetails(data);
		ocrDetailsRef.current = data;
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const getData = useCallback(() => {
		axios.get('/api/tankertrucks/get/' + props.id, {
			params: {},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let newData = {...response.data};
			setData((prev) => {
				if ( prev.incidence?.sp95_liters !== undefined ) newData.incidence.sp95_liters = prev.incidence.sp95_liters; // Prevent change input value when changing approve incidence
				if ( prev.incidence?.diesel_liters !== undefined ) newData.incidence.diesel_liters = prev.incidence.diesel_liters; // Prevent change input value when changing approve incidence
				if ( prev.incidence?.approved !== undefined ) newData.incidence.approved = prev.incidence.approved; // Prevent change radio checked when changing approve incidence
				if ( prev.observations !== undefined ) newData.observations = prev.observations; // Prevent change observations when updating content
				return newData;
			});
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}, [props.id, forceReload]);

	const deleteTankerTruck = () => {
		let c = window.confirm('¿Seguro que quieres eliminar esta cisterna?');
		if ( !c ) return false;

		axios.delete('api/tankertrucks/delete/' + data.id, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			props.close();
			props.forceReload();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al eliminar');
		});	
	}

	const cancelTankerTruck = () => {
		let c = window.confirm('¿Seguro que quieres cancelar esta cisterna?');
		if ( !c ) return false;

		axios.post('api/tankertrucks/cancel/' + data.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			props.close();
			props.forceReload();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al cancelar');
		});	
	}

	const viewPhoto = (id) => {
		axios.get('api/tankertrucks/view-photo/' + id, {
			cancelToken: axiosCancelToken.token,
			responseType: 'blob'
		}).then((response) => {
	    	const url = window.URL.createObjectURL(new Blob([response.data], {type: 'image/jpg'}));
	        window.open(url);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
	}

	const setIncidenceField = (fieldname, value) => {
		let incidence = {...data.incidence};
		incidence[fieldname] = value;
		setData((prev) => ({...prev, incidence: incidence}));
	}

	const saveIncidence = () => {
		data.manual = true; // Set manual on DB

		axios.post('api/tankertrucks/save-incidence/' + data.id, data.incidence, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			props.forceReload();

			let tankerTruckUpdated = response.data.tankertruck;
			setData({...tankerTruckUpdated});

			// Show deviation modal
			if ( tankerTruckUpdated.incidence.type === 'excess' && tankerTruckUpdated.incidence.approved === '1' ) {
				setDeviationModal(true);
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const getProbeData = async (showLoader = true) => {
		setProbeData(null);
		if ( showLoader ) setProbeLoading(true);
		await axios.get('api/probes/get/' + data.station_id, {
			params: {},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			setProbeData(response.data);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
		setProbeLoading(false);
	}

	const updateObservations = () => {
		axios.post('/api/tankertrucks/update-observations/' + props.id, 
			{ observations: data.observations}, 
			{ cancelToken: axiosCancelToken.token }
		).then((response) => {
			
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error');
		});	
	}

	const toggleOcrDetails = (e, id) => {
        e.stopPropagation();

        setOcrDetails(ocrDetails && ocrDetails === id ? null : id);
    }

	const resendToPegasus = (id) => {
		axios.post('/api/tankertrucks/resend-pegasus/' + id, 
			{}, 
			{ cancelToken: axiosCancelToken.token }
		).then((response) => {
			setForceReload(Math.random());
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error');
		});	
	}

	useEffect(() => {
		getData();
	}, [getData]);

	useEffect(() => {
		let reloadInterval = setInterval(() => {
			getData();
		}, 5000);

		return function cleanup() {
			clearInterval(reloadInterval);
		}
	}, [getData]);

	return (
		<Tr className="details" isDeviation={data.parent_id ? true : false}>
			<td colSpan="100%">
				<div className="row ms-0 me-0">
					{ !data?.id && 
						<div className="col-md-12 text-center">
							{loader}
						</div>
					}

					{ data.id && 
						<React.Fragment>
							<div className="col-md-5">
								<div className="d-flex justify-content-between w-100 border-bottom">
									<b className="fw-bold">CISTERNA</b>
									<span className="ms-auto">
										{data.carrier} {data.carrier_code}
									</span>
									<span className="ms-auto">
										{moment(data.date).format('DD-MM-YYYY')}
										&nbsp;entre las&nbsp;
										{moment('2022-09-16 ' + data.hour_expected_init).format('HH:mm')} - {moment('2022-09-16 ' + data.hour_expected_end).format('HH:mm')}
									</span>
								</div>
								<div className="row">
									<div className="col-md-6">
										<TableDetails>
											<tbody>
												{ (data.date_in && moment(data.date).format('YYYY-MM-DD') !== moment(data.date_in).format('YYYY-MM-DD')) &&
													<tr>
														<td>
															{ data.date_in ?
																<i className="bi bi bi-check-circle-fill text-success"></i>
																:
																<i className="bi bi-circle-fill" style={{color: 'var(--bs-gray-300)'}}></i>
															}
														</td>
														<td>Día de llegada</td>
														<td>{data.date_in ? moment(data.date_in).format('DD-MM-YYYY') : '-'}</td>
													</tr>
												}
												<tr>
													<td>
														{ data.date_in ?
															<i className="bi bi bi-check-circle-fill text-success"></i>
															:
															<i className="bi bi-circle-fill" style={{color: 'var(--bs-gray-300)'}}></i>
														}
													</td>
													<td>Hora de llegada</td>
													<td>{data.date_in ? moment(data.date_in).format('HH:mm') : '-'}</td>
												</tr>

												{ (data.date_out &&moment(data.date).format('YYYY-MM-DD') !== moment(data.date_out).format('YYYY-MM-DD')) &&
													<tr>
														<td>
															{ data.date_out ?
																<i className="bi bi bi-check-circle-fill text-success"></i>
																:
																<i className="bi bi-circle-fill" style={{color: 'var(--bs-gray-300)'}}></i>
															}
														</td>
														<td>Día de salida</td>
														<td>{data.date_out ? moment(data.date_out).format('DD-MM-YYYY') : '-'}</td>
													</tr>
												}
												<tr>
													<td>
														{ data.date_out ?
															<i className="bi bi bi-check-circle-fill text-success"></i>
															:
															<i className="bi bi-circle-fill" style={{color: 'var(--bs-gray-300)'}}></i>
														}
													</td>
													<td>Hora de salida</td>
													<td>{data.date_out ? moment(data.date_out).format('HH:mm') : '-'}</td>
												</tr>
												<tr>
													<td>
														{ (data.date_out && data.incidence?.diesel_liters === undefined) ?
															<i className="bi bi bi-check-circle-fill text-success"></i>
															:
															<i className="bi bi-circle-fill" style={{color: 'var(--bs-gray-300)'}}></i>
														}
													</td>
													<td>Cantidad GOA</td>
													<td>{data.diesel_liters ?? '-'}</td>
												</tr>
												{ data.diesel_litrosaditivados > 0 &&
													<tr>
														<td>
															{ data.date_out ?
																<i className="bi bi bi-check-circle-fill text-success"></i>
																:
																<i className="bi bi-circle-fill" style={{color: 'var(--bs-gray-300)'}}></i>
															}
														</td>
														<td>Cantidad GOA <span className="badge text-dark">ADITIVADO</span></td>
														<td>{data.diesel_litrosaditivados ?? '-'}</td>
													</tr>
												}
												<tr>
													<td>
														{ (data.date_out && data.incidence?.sp95_liters === undefined) ?
															<i className="bi bi bi-check-circle-fill text-success"></i>
															:
															<i className="bi bi-circle-fill" style={{color: 'var(--bs-gray-300)'}}></i>
														}
													</td>
													<td>Cantidad SP</td>
													<td>{data.sp95_liters ?? '-'}</td>
												</tr>
												{ data.sp95_litrosaditivados > 0 &&
													<tr>
														<td>
															{ data.date_out ?
																<i className="bi bi bi-check-circle-fill text-success"></i>
																:
																<i className="bi bi-circle-fill" style={{color: 'var(--bs-gray-300)'}}></i>
															}
														</td>
														<td>Cantidad SP <span className="badge text-dark">ADITIVADO</span></td>
														<td>{data.sp95_litrosaditivados ?? '-'}</td>
													</tr>
												}
												<tr>
													<td>
														{ data.seal ?
															<i className="bi bi bi-check-circle-fill text-success"></i>
															:
															<i className="bi bi-circle-fill" style={{color: 'var(--bs-gray-300)'}}></i>
														}
													</td>
													<td>Precinto</td>
													<td>{data.seal ? 'SI' : 'NO'}</td>
												</tr>
												<tr>
													<td>
														{ data.photos?.length > 0 ?
															<i className="bi bi bi-check-circle-fill text-success"></i>
															:
															<i className="bi bi-circle-fill" style={{color: 'var(--bs-gray-300)'}}></i>
														}
													</td>
													<td>Albarán</td>
													<td></td>
												</tr>
												<tr>
													<td>
														{ data.observations ?
															<i className="bi bi bi-check-circle-fill text-success"></i>
															:
															<i className="bi bi-circle-fill" style={{color: 'var(--bs-gray-300)'}}></i>
														}
													</td>
													<td style={{whiteSpace: 'nowrap'}}>
														Observaciones 
														
														{ hasCapability('tankertrucks', 'edit') &&
															!editingObservations ?
																<button className="btn btn-link btn-sm text-secondary p-0 ms-2" onClick={() => setEditingObservations(true)}>
																	<i className="bi bi-pencil"></i>
																</button>
															:
																<button 
																	className="btn btn-link btn-sm text-success p-0 ms-2" 
																	onClick={() => {
																		setEditingObservations(false);
																		updateObservations();
																	}}
																>
																	<i className="bi bi-check-circle"></i>
																</button>
														}
													</td>
													<td></td>
												</tr>
												{ (data.observations || editingObservations) &&
													<tr>
														<td colSpan="100%" className="ps-4 text-start">
															{ editingObservations ?
																<textarea className="form-control form-control-sm" value={data.observations ?? ''} maxLength="2000" onChange={(e) => setData((prev) => ({...prev, observations: e.target.value}))} />
																:
																data.observations
															}
														</td>
													</tr>
												}
											</tbody>
										</TableDetails>
									</div>
									<div className="col-md-6">
										{data.manualdata &&
											<>
												<b className="d-block mt-1">
													LECTURA EXPENDEDOR
													{data.manualdata?.sent_at &&
														<>
															&nbsp;<i className="bi bi bi-check-circle-fill text-success"></i>
														</>
													}
												</b> 
												
												<TableDetails className="mb-2">
													<tbody>
														{data.manualdata?.albaran &&
															<tr>
																<td className="text-start ps-2">
																	Nº Albaran
																</td>
																<td>
																	{data.manualdata?.albaran}
																</td>
															</tr>
														}
														{data.manualdata?.goa_liters !== 0 &&
															<tr>
																<td className="text-start ps-2">
																	Cantidad GOA
																</td>
																<td>
																	{data.manualdata?.goa_liters}
																</td>
															</tr>
														}
														{data.manualdata?.sp95_liters !== 0 &&
															<tr>
																<td className="text-start ps-2">
																	Cantidad SP95
																</td>
																<td>
																	{data.manualdata?.sp95_liters}
																</td>
															</tr>
														}
													</tbody>
												</TableDetails>
											</>
										}
										
										
										{ data.photos.length > 0 &&
											<>
												<b className="d-block mt-1">LECTURA OCR</b>
												<div className="d-flex flex-column mb-2">
													{ data.photos.map((pEl, pIdx) => {
														return (
															<div key={pIdx} className="d-flex w-100 align-items-center justify-content-between mt-2">
																<button key={pIdx} className="btn btn-sm m-0 py-0 d-inline-flex" onClick={() => viewPhoto(pEl.id)}>
																	<i className="bi bi-image"></i>&nbsp;
																	Albarán foto {pIdx+1}
																</button>
																{pEl.ocr_result === 1 &&
																	<>
																		<button className="btn p-0 m-0 ms-auto" onClick={(e) => toggleOcrDetails(e, pEl.id)}>
																			<i className={"bi bi-qr-code ms-auto " + (pEl.ocr_sent ? ' text-primary ' : ' text-danger ') + (pEl.ocr_sp95_qty_alert || pEl.ocr_goa_qty_alert ? ' bg-warning ' : '')}></i>
																		</button>
																		<div 
																			className="position-relative"
																			onMouseLeave={(e) => toggleOcrDetails(e, null)}
																		>
																			{ ocrDetails === pEl.id &&
																				<OcrPopup
																					id={pEl.id}
																					data={pEl.ocr}
																					goaQtyAlert={pEl.ocr_goa_qty_alert}
																					sp95QtyAlert={pEl.ocr_sp95_qty_alert}
																					sentAt={pEl.sent_at}
																					sentLog={pEl.sent_log}
																					forceReload={() => {
																						setForceReload(Math.random());
																					}}
																				/>
																			}
																		</div>
																	</>
																}
															</div>
														)
													}) }
												</div>
											</>
										}

										<b className="d-block mt-1">ENVÍO A PEGASUS</b>
										<TableDetails>
											<tbody>
												<tr>
													<td className="text-start">
														<i className="bi bi-code-slash"></i>&nbsp;
														{(data.numpedido_transporte?.length > 0 && !data.indicus_sent_at) && 'Datos pendientes de enviar a Pegasus'}
														{data.indicus_sent_at && 'Datos enviados a Pegasus el ' + moment(data.indicus_sent_at).format('DD-MM-YYYY HH:mm:ss')}
													</td>
												</tr>
												{(data.indicus_sent_at && data.indicus_sent_status === 'error') && 
													<tr>
														<td className="text-start text-danger">
															<i className="bi bi-code-slash"></i>&nbsp;
															Envío a Pegasus con errores, revisar historial 
															<button className="btn btn-light btn-sm p-0 px-1" onClick={() => resendToPegasus(data.id)}>reenviar</button>
														</td>
													</tr>
												}
												{data.has_carrier_send_logs &&
													<tr>
														<td>
															<button className="btn btn-light btn-sm p-0 px-1" onClick={() => setCarrierSendLogsModal(data.id)}>Historial de envíos Pegasus</button>
														</td>
													</tr>
												}
											</tbody>
										</TableDetails>
									</div>
								</div>
							</div>
							<IncidencesWrapper className="col-md-7 ps-5">
								<div className="row">
									<div className="col-md-4 text-start">
										{ data.incidence_at &&
											<React.Fragment>
												<div className="border-bottom mb-3">
													<i className="bi bi-exclamation-triangle text-danger"></i>
													&nbsp;
													<b>DESCARGA CON INCIDENCIAS</b>
												</div>
												<TableIncidenceType>
													<tbody>
														<tr>
															<td><i className={'bi bi-circle' + (data.incidence.type === 'address' ? '-fill text-danger' : '')}></i></td>
															<td>OTRA DIRECCIÓN</td>
														</tr>
														<tr>
															<td><i className={'bi bi-circle' + (data.incidence.type === 'excess' ? '-fill text-danger' : '')}></i></td>
															<td>EXCESO COMBUSTIBLE</td>
														</tr>
														<tr>
															<td><i className={'bi bi-circle' + (data.incidence.type === 'quantities' ? '-fill text-danger' : '')}></i></td>
															<td>OTRAS CANTIDADES</td>
														</tr>
														<tr>
															<td><i className={'bi bi-circle' + (data.incidence.type === 'others' ? '-fill text-danger' : '')}></i></td>
															<td>OTROS</td>
														</tr>
													</tbody>
												</TableIncidenceType>
											</React.Fragment>
										}
									</div>
									<div className="col-md-4">
										{ data.incidence_at &&
											<React.Fragment>
												<div className="border-bottom text-danger fw-bold mb-3">
													{ data.incidence.type === 'address' && <>OTRA DIRECCIÓN</>}
													{ data.incidence.type === 'quantities' && <>OTRAS CANTIDADES</>}
													{ data.incidence.type === 'excess' && <>EXCESO COMBUSTIBLE</>}
													{ data.incidence.type === 'others' && <>OTROS</>}
												</div>

												{ (data.incidence?.type === 'quantities' || data.incidence?.type === 'excess') &&
													<IncidenceQuantities>
														<tbody>
															<tr>
																<td>CANTIDAD GOA</td>
																<td>
																	<input type="number" className="form-control form-control-sm no-arrows" value={data.incidence.diesel_liters} onChange={(e) => setIncidenceField('diesel_liters', e.target.value)} />
																</td>
															</tr>
															<tr>
																<td>CANTIDAD SP</td>
																<td>
																	<input type="number" className="form-control form-control-sm no-arrows" value={data.incidence.sp95_liters} onChange={(e) => setIncidenceField('sp95_liters', e.target.value)} />
																</td>
															</tr>
															<tr>
																<td></td>
																<td>
																	<div className="text-center mt-2">
																		<label className="d-inline-flex align-items-center">
																			<input type="radio" name={data.id + '-incidence-quantities-radio'} value={1} checked={parseInt(data.incidence.approved) === 1} onChange={(e) => setIncidenceField('approved', e.target.value)} className="me-1" /> 
																			APROBAR
																		</label>
																		<div></div>
																		<label className="d-inline-flex align-items-center">
																			<input type="radio" name={data.id + '-incidence-quantities-radio'} value={0} checked={parseInt(data.incidence.approved) === 0} onChange={(e) => setIncidenceField('approved', e.target.value)} className="me-1" /> 
																			DENEGAR
																		</label>
																		<div className="text-center">
																			<button className="btn btn-sm btn-plenoil-blue-light px-3 py-0 mt-1" onClick={saveIncidence}>ENVIAR</button>
																		</div>
																	</div>
																</td>
															</tr>
														</tbody>
													</IncidenceQuantities>
												}

												{ data.incidence?.type === 'others' &&
													<React.Fragment>
														<div className="bg-white py-1 px-2 rounded border small">
															{data.incidence.message}
														</div>
														<div className="mt-2">
															<label className="d-inline-flex align-items-center">
																<input type="radio" name={data.id + '-incidence-quantities-radio'} value={1} checked={parseInt(data.incidence.approved) === 1} onChange={(e) => setIncidenceField('approved', e.target.value)} className="me-1" /> 
																APROBAR
															</label>
															<div></div>
															<label className="d-inline-flex align-items-center">
																<input type="radio" name={data.id + '-incidence-quantities-radio'} value={0} checked={parseInt(data.incidence.approved) === 0} onChange={(e) => setIncidenceField('approved', e.target.value)} className="me-1" /> 
																DENEGAR
															</label>
															<div className="">
																<button className="btn btn-sm btn-plenoil-blue-light px-3 py-0 mt-1" onClick={saveIncidence}>ENVIAR</button>
															</div>
														</div>
													</React.Fragment>
												}

												{ data.incidence?.type === 'address' &&
													<div className="mt-2">
														<label className="d-inline-flex align-items-center">
															<input type="radio" name={data.id + '-incidence-quantities-radio'} value={1} checked={parseInt(data.incidence.approved) === 1} onChange={(e) => setIncidenceField('approved', e.target.value)} className="me-1" /> 
															APROBAR
														</label>
														<div></div>
														<label className="d-inline-flex align-items-center">
															<input type="radio" name={data.id + '-incidence-quantities-radio'} value={0} checked={parseInt(data.incidence.approved) === 0} onChange={(e) => setIncidenceField('approved', e.target.value)} className="me-1" /> 
															DENEGAR
														</label>
														<div className="">
															<button className="btn btn-sm btn-plenoil-blue-light px-3 py-0 mt-1" onClick={saveIncidence}>ENVIAR</button>
														</div>
													</div>
												}
											</React.Fragment>
										}

										{ data.status === 5 &&
											<div className="border-bottom mt-3 mb-3">
												<i className="bi bi-check-circle-fill text-success"></i>
												&nbsp;
												<b>DESCARGA COMPLETADA</b>
											</div>
										}
									</div>
									<div className="col-md-4 text-end">
										{ !data.child &&
											<button className="btn btn-sm btn-plenoil-blue-light me-3" onClick={() => setDeviationModal(true)}>
												<i className="bi bi-shuffle"></i> Desviar
											</button>
										}

										<ButtonClose onClick={props.close}>
											<i className="bi bi-x"></i>
											<div>Cerrar</div>
										</ButtonClose>

										<div className="mt-2"></div>

										<div className="probes_wrapper">
											<button className="btn btn-sm btn-plenoil-orange" onClick={() => getProbeData(true)} disabled={probeLoading}>
												Pedir sonda
												{ probeLoading && loader }
											</button>

											{ probeData &&
												<React.Fragment>
													<div className="py-2">
														<Tank
															className="w-100 mb-2"
															type="diesel"
															percent={probeData.diesel_percent}
															available={probeData.diesel_available}
															width={100}
														/>
														<Tank
															className="w-100"
															type="splomo"
															percent={probeData.splomo_percent}
															available={probeData.splomo_available}
															width={100}
														/>
													</div>
													{ probeData.error &&
														<div className="invalid-feedback d-block mb-3"><b>Error al pedir la sonda:</b> {probeData.error}</div>
													}
												</React.Fragment>
											}
										</div>
										
										{ !data.cancelled_at &&
											<ButtonCancel onClick={cancelTankerTruck}>
												<i className="bi bi-x-circle"></i> Cancelar
											</ButtonCancel>
										}

										{ data.is_deletable &&
											<ButtonDelete onClick={deleteTankerTruck}>
												<i className="bi bi-trash"></i> Eliminar
											</ButtonDelete>
										}
									</div>
								</div>
							</IncidencesWrapper>
						</React.Fragment>
					}
				</div>

				{ deviationModal && 
					<DeviationModal 
						parent={data}
						closeCallback={() => {
							setDeviationModal(false);
							props.forceReload();
							props.close();
						}}
					/>
				}

				{ carrierSendLogsModal && 
					<CarrierSendLogsModal 
						id={carrierSendLogsModal}
						closeCallback={() => {
							setCarrierSendLogsModal(false);
						}}
					/>
				}
			</td>
		</Tr>
	);
}


