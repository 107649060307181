import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, NavLink, useParams, useSearchParams } from "react-router-dom";
import LoggedLayout from '../../layouts/LoggedLayout';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import PageHeader from 'components/PageHeader';
import Paginator from 'components/Paginator';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';
import DateDropdown from 'components/DateDropdown';
import { hasCapability } from 'helpers/user';
import { assets } from 'helpers/generic';
import Details from 'pages/TankerTrucks/Details';
import ImportExcelModal from 'pages/TankerTrucks/ImportExcelModal';
import ExportExcelModal from 'pages/TankerTrucks/ExportExcelModal';
import { Popover } from 'bootstrap';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { default as TankerTrucksService } from 'services/TankerTrucks';

const TableResponsive = styled.div` 
	@media (min-width: 992px) {
		overflow-x: visible;
	}
`;

const Table = styled.table`
	th,
	td {
		vertical-align: middle;
		position: relative;

		&:nth-child(1) {
			width: 40px;
			text-align: center;
			white-space: nowrap;
			
			input[type=checkbox] {
				margin-right: 5px;
			}
		}

		&:nth-child(2) {

			.notification_status {

			}
			
			.indicus_sent_status {
				width: 22px;
				height: 22px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 10px;
				color: white;

				&.success {
					background: var(--plenoil-green);
				}

				&.error {
					background: var(--plenoil-red);
				}
			} 

			.station_code {
				width: 22px;
				height: 22px;
				font-size: 10px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 10px;
			}

			.ocr_qty_alert {
				display: inline-flex;
				justify-content: center;
				align-items: center;

				img {
					width: 15px;
				}
			}
		}

		&:nth-child(3) {
			width: 100px;
			text-align: center;
			white-space: nowrap;
		}

		&:nth-child(4),
		&:nth-child(5) {
			width: 40px;
			text-align: center;
		}

		&:nth-child(6), 
		&:nth-child(9) { 
			width: 80px;
			text-align: center;

			div {
				display: flex;
				justify-content: space-between;
				width: 90%;
				margin: 0 auto;
			}
		}

		&:nth-child(7),
		&:nth-child(8) {
			width: 60px;
			text-align: center;
		}

		&:nth-child(10) {
			width: 40px;
			text-align: center;
		}
		&:nth-child(11) {
			width: 80px;
			text-align: center;

			&.chat {
				i {
					color: var(--plenoil-green);

				}

				&.disabled {
					opacity: 0.8;
				}
			}
		}
	}

	.deviation-status {
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: 1px solid var(--bs-gray-300);
		width: 40px;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}

	.deviation-icon {
		margin-left: 50px;
	}

	.order {
		font-size: 10px;
		font-style: italic;
	}

	.status-icon {
		display: inline-block;

		& > * {
			width: 100%;
			height: 100%;
			display: inline-flex;
			justify-content: center;
			align-items: center;
		}
	}
`;

const Filters = styled.div`
	display: flex;
	justify-content: end;

	@media (max-width: 1200px) {
		justify-content: center;
		flex-direction: column;

		& > *,
		.empowered-selector {
			width: 100% !important;
			display: block !important;
		}

		& > * {
			margin-bottom: 10px;
		}
	}
`;

const DateDropDownWrapper = styled.div` 
	padding: 0;
	min-height: 0;
	width: 192px;
	display: inline-block;

	& > .date-dropdown {
		display: block;

		& > .dropdown {
			width: 100%;
			display: block !important;

			& > .btn {
				padding: 0;
				width: 100% !important;
				display: flex;
				align-items: center;
				padding: 0 5px;

				small {
					margin-left: auto;
				}
			}
		}
	}
`;

const EmpoweredSelectorWrapper = styled.div` 
	margin-right: 10px;

	.empowered-selector {
		width: 200px;
		
		.empowered-selector_label {
			height: 28px;
			white-space: nowrap;
			overflow: hidden;

			&:hover {
				background: white;
			}

			.remove-icon {
				background: white;
				border-radius: 50%;
			}
		}
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;
let loadingTankerTrucks = true;

export default function TankerTrucks() {
	const [queryParams] = useSearchParams();
	const params = useParams();
	let status = params.status ?? null;

	let navigate = useNavigate();

	let [forceReload, setForceReload] = useState(null);
	let [search, _setSearch] = useState(null);
	let [counters, setCounters] = useState({});
	let [tankertrucks, setTankerTrucks] = useState([]);
	let [selectedChecks, setSelectedChecks] = useState([]);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('date');
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [details, setDetails] = useState({});
	let [station, setStation] = useState(null);
	let [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
	let [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
	let [ocrFilter, setOcrFilter] = useState('');
	let [showImportExcelModal, setShowImportExcelModal] = useState(false);
	let [showExportExcelModal, setShowExportExcelModal] = useState(false);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setTankerTrucks({...tankertrucks, data: undefined});
		_setPage(page);
	}

	const setSearch = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			_setSearch(value);
		}, 500);
	}

	const getTankerTrucks = useCallback(async () => {
		loadingTankerTrucks = true;

		let result = await axios.get('api/tankertrucks/list', {
			params: {
				page: page,
				sort: sortField,
				direction: sortDirection,
				status: status && status.indexOf(',') !== -1 ? status.split(',') : status,
				date_from: dateFrom,
				date_to: dateTo,
				station_id: station?.id,
				search: search
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			setTankerTrucks({...response.data});
			setSkeletonRows(response.data.data.length);
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});

		loadingTankerTrucks = false;

		return result;
	}, [page, sortField, sortDirection, status, dateFrom, dateTo, station, search]);

	const getCounters = useCallback(async() => {
		// TODO: add to services file
		axios.get('/api/tankertrucks/get-counters', {
			params: {
				date_from: dateFrom,
				date_to: dateTo,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			setCounters((prev) => ({...prev, ...response.data}));
		  }).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}, [dateFrom, dateTo]);

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};	

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);

			// Remove details
			delete details[rowId];
			setDetails({...details});
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	const changeStatus = (status) => {
		navigate('/tankertrucks/status/' + status);
	}

	const setDatesRange = (range) => {
		setDateFrom(range.from);
		setDateTo(range.to);
	}

	const loadStations = (input, callback) => {
		// TODO: add to services file
		axios.get('/api/stations/list', {
			params: {
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});

			callback(formatted);
	  	}).catch((error) => {			
			if ( axios.isCancel(error) ) return;
		});	
	}

	const openChat = (e, station_id) => {
		e.stopPropagation();

		const event = new CustomEvent('open-chat', {detail: {stationId: station_id, channel: 'tankertrucks'}});
		document.dispatchEvent(event);
	}

	const toggleSelectedCheck = (e, checkId) => {
		e.stopPropagation();

		let newChecks = [...selectedChecks];

		let checked = newChecks.indexOf(checkId);
		if ( checked !== -1 ) {
			newChecks.splice(checked, 1);
		} else {
			newChecks.push(checkId);
		}

		setSelectedChecks(newChecks);
	}

	const deleteSelected = async () => {
		let c = window.confirm('¿Seguro que quieres eliminar estas comprobaciones de precios?');
		if ( !c ) return;

		let result = await TankerTrucksService.deleteBulk(selectedChecks);
		if ( !result.status ) {
			toast.error('Error al cambiar el estado');
            return;
		}

		setSelectedChecks([]);
		setForceReload(Math.random());
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		let getDataAsync = async () => {
			// Fix, cancel previous (because reloadInterval)
			if ( axiosCancelToken ) {
				axiosCancelToken.cancel();
				axiosCancelToken = axios.CancelToken.source();
			}

			await getTankerTrucks();
		}
		getDataAsync();

		let reloadInterval = setInterval(() => {
			if ( loadingTankerTrucks ) return;

			getTankerTrucks();
		}, 5000);

		return function cleanup() {
			clearInterval(reloadInterval);
		}
	}, [forceReload, getTankerTrucks]);

	// Get header counters
	useEffect(() => {
		getCounters();

		let getCountersInterval = setInterval(() => {
			getCounters();
		}, 5000);

		return function cleanup() {
			clearInterval(getCountersInterval);
		}
	}, [getCounters]);

	// Popovers
	useEffect(() => {	
		var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
		popoverTriggerList.map(function (popoverTriggerEl) {
		  return new Popover(popoverTriggerEl, {
		  	trigger: 'focus hover'
		  })
		})
	}, [tankertrucks]);

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<PageHeader>
					<span className="header-column header-title">Cisternas</span>
					<span className="header-column" onClick={() => changeStatus(0)} role="button">
						<div className="header-column-text">
							Programadas <Status status={0} className="d-inline-block ms-1 me-4" />
						</div>
						<div className="header-column-number" style={{color: 'var(--plenoil-blue)'}}>
							{counters[0] ?? '-'}
						</div>
					</span>
					<span className="header-column" onClick={() => changeStatus(1)} role="button">
						<div className="header-column-text">
							En camino <Status status={1} className="d-inline-block ms-1 me-4" />
						</div>
						<div className="header-column-number" style={{color: 'var(--plenoil-orange)'}}>
							{counters[1] ?? '-'}
						</div>
					</span>
					<span className="header-column" onClick={() => changeStatus(2)} role="button">
						<div className="header-column-text">
							Con retraso <Status status={2} className="d-inline-block ms-1 me-4" style={{color: 'var(--plenoil-red)'}} />
						</div>
						<div className="header-column-number" style={{color: 'var(--plenoil-red)'}}>
							{counters[2] ?? '-'}
						</div>
					</span>
					<span className="header-column" onClick={() => changeStatus(3)} role="button">
						<div className="header-column-text">
							Incidencias <Status status={3} className="d-inline-block ms-1 me-4" />
						</div>
						<div className="header-column-number" style={{color: 'var(--plenoil-red)'}}>
							{counters[3] ?? '-'}
						</div>
					</span>
					<span className="header-column" onClick={() => changeStatus(4)} role="button">
						<div className="header-column-text">
							En descarga <Status status={4} className="d-inline-block ms-1 me-4" />
						</div>
						<div className="header-column-number" style={{color: 'var(--plenoil-green)'}}>
							{counters[4] ?? '-'}
						</div>
					</span>
					<span className="header-column header-icon-end">
						<i className="bi bi-truck"></i>
					</span>
				</PageHeader>
			</div>

			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-primary text-white p-2">
					<div className="row">
						<div className="col-md-2">
							Listado de cisternas
						</div>
						<Filters className="col-md-10">
							{ (selectedChecks.length > 0 && hasCapability('tankertrucks', 'delete'))  &&
								<button className="btn btn-sm btn-plenoil-blue-light py-0 px-2 me-2" onClick={() => deleteSelected()}>Eliminar</button>
							}

							<div id="search-box" className="me-2">
								<input type="search" className="form-control form-control-sm" placeholder="Albarán, Ptra, Pcar" onChange={(e) => setSearch(e.target.value)} />
							</div>

							<EmpoweredSelectorWrapper>
								<EmpoweredSelector
									load={(input, callback) => loadStations(input, callback)}
									onChange={(value, text) => setStation(value)}
									timeout={200}
									label={station?.name}
									value={station?.id ?? null}
									placeholder="Estación"
								/>
							</EmpoweredSelectorWrapper>

							<DateDropDownWrapper className="form-control form-control-sm me-2">
								<DateDropdown 
									defaultPeriod={'today'} 
									onChange={(period, range) => setDatesRange(range)}
									onlyDates={true}
								/>
							</DateDropDownWrapper>

							<div className="me-2">
								<select className="form-control form-control-sm" onChange={(e) => setOcrFilter(e.target.value)}>
									<option>- OCR -</option>
									<option value="readed+sent">Azul</option>
									<option value="readed+unsent">Rojo</option>
									<option value="alert">Con Amarillo</option>
									<option value="unreaded">No leído</option>
								</select>
							</div>

							<button className="btn btn-sm btn-light p-0 px-2 me-2 text-nowrap" onClick={() => setShowExportExcelModal(true)}>EXPORTAR EXCEL</button>

							{ hasCapability('tankertrucks', 'add') &&
								<React.Fragment>
									<button className="btn btn-sm btn-light p-0 px-2 me-2 text-nowrap" onClick={() => setShowImportExcelModal(true)}>IMPORTAR EXCEL</button>
									<NavLink to="/tankertrucks/add-manual" className="btn btn-sm btn-light p-0 px-2 d-inline-flex align-items-center text-nowrap">ENVÍO MANUAL</NavLink>
								</React.Fragment>
							}
						</Filters>
					</div>
				</div>
			</div>

			<div className="col-md-12 mb-3">
				<TableResponsive className="table-responsive shadow-sm rounded">
					<Table className="table table-sm table-bordered table-striped table-custom bg-white mb-0">
						<thead>
							<tr>
								<th>Estado</th>
								<ThSortable direction={sortDirection} active={sortField === 'station.name'} onClick={() => sortTableClick('station.name')}>Estación</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Día</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'date_in'} onClick={() => sortTableClick('date_in')}>Llegada</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'date_out'} onClick={() => sortTableClick('date_out')}>Salida</ThSortable>
								<th>Litros</th>
								<th>Precinto</th>
								<th>Dirección</th>
								<th>Cantidades</th>
								<th>Otras</th>
								<th>Chat</th>
							</tr>
						</thead>
						<tbody>
							{ 	
								tankertrucks.data?.filter(el => {
									if ( ocrFilter == 'readed+sent' ) return el.has_ocr === true && el.ocr_sent;
									if ( ocrFilter == 'readed+unsent' ) return el.has_ocr === true && !el.ocr_sent;
									if ( ocrFilter == 'alert' ) return el.has_ocr === true && el.ocr_qty_alert;
									if ( ocrFilter == 'unreaded' ) return el.has_ocr === false;
									return true;
								}).map((el, idx) => {
									return (
										<React.Fragment key={el.id}>
											<tr className={'has-details ' + (detailsOpened.includes(el.id) ? 'opened' : '')} onClick={() => toggleDetails(el.id)}>
												<td onClick={(e) => toggleSelectedCheck(e, el.id)}>
													{ el.is_deletable &&
														<input type="checkbox" checked={selectedChecks.indexOf(el.id) !== -1} onChange={() => null} />
													}

													{ !el.parent_id &&
														<Status status={el.status} parpadeo={el.status === 2 || el.status === 3} incidenceApprovedStatus={el.incidence && el.incidence.approved !== undefined ? el.incidence.approved : null} />
													}
												</td>
												<td>
													<div className="d-flex justify-content-between">
														<div>
															{el.parent_id && 
																<React.Fragment>
																	<div className="deviation-status">
																		<Status status={el.status} parpadeo={el.status === 2 || el.status === 3} />
																	</div>
																	<i className="bi bi bi-arrow-return-right deviation-icon" style={{color: 'var(--plenoil-gray)'}}>&nbsp;</i>
																</React.Fragment>
															}
															{ el.child &&
																<i className="bi bi-shuffle mx-1" style={{color: 'var(--plenoil-green)'}}>&nbsp;</i>
															}
															{el.station?.name}
															<div className="order">{el.order} {el.numpedido_transporte ? '/ ' + el.numpedido_transporte : ''} {el.clh ? '/ ' + el.clh : ''}</div>
														</div>
													
														<div className="d-flex align-items-center">
															{el.manualdata?.sent_at && <span className="badge bg-light text-dark me-2" style={{fontSize: '8px'}}>LECTURA EXPENDEDOR</span>}
															{!el.ocr_sent && el.ocr_qty_alert && <i className="ocr_qty_alert me-2"><img src={assets('/img/litros.svg')} /></i>}
															{el.has_ocr && <i className={'bi bi-qr-code me-2 ' + (el.ocr_sent ? ' text-primary ' : ' text-danger ') + (el.ocr_qty_alert ? ' bg-warning ' : '')}></i>}
															{(el.notified_at && !el.seen_at) && <i className="bi bi-eye-slash-fill notification_status text-danger"></i>}
															{el.seen_at && <i className="bi bi-eye-fill notification_status text-success"></i>}
															{el.indicus_sent_status !== null &&
																<div className={'indicus_sent_status ' + (el.indicus_sent_status)}><i className="bi bi-code-slash"></i></div>
															}
															<span className="bg-dark text-white station_code">{el.station?.octan_id}</span>
														</div>
													</div>
												</td>
												<td>
													<div>{moment(el.date).format('DD-MM-YYYY')}</div>
													<div>{moment('2022-09-16 ' + el.hour_expected_init).format('HH:mm')} - {moment('2022-09-16 ' + el.hour_expected_end).format('HH:mm')}</div>
												</td>
												<td>{el.date_in ? moment(el.date_in).format('HH:mm') : '-'}</td>
												<td>{el.date_out ? moment(el.date_out).format('HH:mm') : '-'}</td>
												<td>
													<div><i className="bi bi-circle-fill" style={{color: 'var(--plenoil-black)'}}></i> {el.diesel_liters}</div>
													<div><i className="bi bi-circle-fill" style={{color: 'var(--plenoil-green)'}}></i> {el.sp95_liters}</div>
												</td>
												<td>
													{ el.seal === 0 && <div><i className="bi bi-slash-circle" style={{color: 'var(--plenoil-red)'}}></i> NO</div>}
													{ el.seal === 1 && <div><i className="bi bi-check-circle-fill" style={{color: 'var(--plenoil-green)'}}></i> SI</div>}
												</td>
												<td>
													{ el.incidence?.type === 'address' &&
														<React.Fragment>
															{el.incidence.approved === "1" && <span className="text-success">Aprobado</span>}
															{el.incidence.approved === "0" && <span className="text-danger">Denegado</span>}
														</React.Fragment>
													}
												</td>
												<td>
													{ el.incidence?.type === 'quantities' &&
														<React.Fragment>
															<div><i className="bi bi-circle-fill" style={{color: 'var(--plenoil-black)'}}></i> {el.incidence.diesel_liters}</div>
															<div><i className="bi bi-circle-fill" style={{color: 'var(--plenoil-green)'}}></i> {el.incidence.sp95_liters}</div>
															{el.incidence.approved === "1" && <span className="text-success">Aprobado</span>}
															{el.incidence.approved === "0" && <span className="text-danger">Denegado</span>}
														</React.Fragment>
													}
												</td>
												<td>
													{ el.incidence?.type === 'others' &&
														<div className="text-center">
															<button className="btn btn-lg p-0" onClick={(e) => e.stopPropagation()} data-bs-container="body" data-bs-toggle="popover" data-bs-placement="left" data-bs-content={el.incidence.message}>
																<i className="bi bi-eye-fill text-danger"></i>
															</button>
														</div>
													}
												</td>
												<td className={'chat ' + (!el.pending_chat_messages_count ? 'disabled' : '')}>
													<button className="btn btn-link btn-sm" onClick={(e) => openChat(e, el.station_id)}><i className={'bi bi-chat-right' + (el.pending_chat_messages_count ? '-fill' : '')}></i></button>
												</td>
											</tr>
											{ detailsOpened.includes(el.id) &&
												<Details 
													id={el.id} 
													forceReload={() => setForceReload(Math.random())}
													close={() => toggleDetails(el.id)}
												/>
											}
										</React.Fragment>
									)
								}) }

							{ tankertrucks.data && !tankertrucks.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

							{ tankertrucks.data === undefined && <TrSkeleton rows={skeletonRows} columns={11} /> }
						</tbody>
					</Table>
				</TableResponsive>
			</div>

			<div className="col-md-12 text-end mb-3">
				<div className="d-inline-block">
					<Paginator
						min={1}
						current={tankertrucks?.current_page}
						max={tankertrucks?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</div>
			</div>

			{ showImportExcelModal && 
				<ImportExcelModal 
					closeCallback={() => {
						setShowImportExcelModal(false);
						setForceReload(Math.random());
					}}
				/>
			}

			{ showExportExcelModal && 
				<ExportExcelModal 
					closeCallback={() => {
						setShowExportExcelModal(false);
						setForceReload(Math.random());
					}}
				/>
			}
		</LoggedLayout>
	);
}


const Status = (props) => {
	let status = props.status;
	let incidenceApprovedStatus = props.incidenceApprovedStatus;

	return (
		<div className={'status-icon ' + (props.className ?? '') + (props.parpadeo ? ' parpadeo ' : '')}>
			{status === 0 && <i className="bi bi-calendar3" style={{color: 'var(--plenoil-gray)'}}></i>}
			{status === 1 && 
				<div style={{color: 'var(--plenoil-orange)', whiteSpace: 'nowrap', letterSpacing: '-5px', marginLeft: '-5px'}}>
					<i className="bi bi-chevron-right"></i>
					<i className="bi bi-chevron-right"></i>
					<i className="bi bi-chevron-right"></i>
				</div>
			}
			{status === 2 && <i className="bi bi-bell-fill" style={{color: 'var(--plenoil-red)'}}></i>}
			{status === 3 && <i className="bi bi-exclamation-triangle-fill" style={{background: 'var(--plenoil-red)', color: 'white'}}></i>}
			{status === 4 && <i className="bi bi-save2" style={{color: 'var(--plenoil-green)'}}></i>}
			{status === 5 && incidenceApprovedStatus === null && <i className="bi bi-check-lg" style={{color: 'var(--plenoil-green)'}}></i>}
			{status === 5 && incidenceApprovedStatus === '0' && <i className="bi bi-slash-circle" style={{color: 'var(--plenoil-green)'}}></i>}
			{status === 5 && incidenceApprovedStatus === '1' && <i className="bi bi-check-circle" style={{color: 'var(--plenoil-green)'}}></i>}
			{status === 6 && <i className="bi bi-x-circle" style={{color: 'var(--plenoil-red)'}}></i>}
		</div>
	);
}